$primary:       #5a7443;
$secondary:     #ef6c00;
$light:         #6c757d;

$min-contrast-ratio: 3;

.calendar-event-cancelled {
    text-decoration: line-through !important;
}

.fc-event-title {
    white-space: normal !important;
    align-items: normal !important;
}
  

@import '../../../../../node_modules/bootstrap/scss/bootstrap';